// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
	Input,
	Select
} from "../../../_metronic/_partials/controls";

import { useIntl } from "react-intl";


export function EditForm({ saveItem, item, actionsLoading, onHide }) {

	const intl = useIntl();

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={item}
				onSubmit={(values) => {
					saveItem(values);
				}}
			>
				{({ handleSubmit }) => (
					<>
						<Modal.Body className="overlay overlay-block cursor-default">
							{actionsLoading && (
								<div className="overlay-layer bg-transparent">
									<div className="spinner spinner-lg spinner-success" />
								</div>
							)}
							<Form className="form form-label-right">
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Customer.Nickname" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Field
											name="nickname"
											component={Input}
											placeholder={ intl.formatMessage({ id: "Table.Customer.Nickname" }) }
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Customer.Mobile" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Field
											name="mobile"
											component={Input}
											placeholder={ intl.formatMessage({ id: "Table.Customer.Mobile" }) }
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Customer.Email" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Field
											name="email"
											component={Input}
											placeholder={ intl.formatMessage({ id: "Table.Customer.Email" }) }
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Customer.Type" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Select name="type">
											<option value="0">{ intl.formatMessage({ id: "Table.Customer.Customer" }) }</option>
											<option value="1">{ intl.formatMessage({ id: "Table.Customer.Staff" }) }</option>
										</Select>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Customer.Remarks" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Field name="remarks">
											{({ field, form, meta }) => (
												<textarea name="remarks" {...field} className="form-control form-control-solid"></textarea>
											)}
										</Field>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Customer.Status" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Select name="status">
											<option value="0">{ intl.formatMessage({ id: "Table.Customer.Suspended" }) }</option>
											<option value="1">{ intl.formatMessage({ id: "Table.Customer.Active" }) }</option>
										</Select>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Customer.Notification" }) }
									</label>
									<div className="col-lg-3 col-xl-3 p-3">
										<div className="checkbox-inline">
											<label className="checkbox">
												<Field type="checkbox" name="push_noti" defaultChecked={ item.push_noti } />
												<span></span>{ intl.formatMessage({ id: "Table.Customer.PushNotification" }) }
											</label>
										</div>
									</div>
									<div className="col-lg-3 col-xl-3 p-3">
										<div className="checkbox-inline">
											<label className="checkbox">
												<Field type="checkbox" name="sms_noti" defaultChecked={ item.sms_noti } />
												<span></span>{ intl.formatMessage({ id: "Table.Customer.SMSNotification" }) }
											</label>
										</div>
									</div>
									<div className="col-lg-3 col-xl-3 p-3">
										<div className="checkbox-inline">
											<label className="checkbox">
												<Field type="checkbox" name="email_noti" defaultChecked={ item.email_noti } />
												<span></span>{ intl.formatMessage({ id: "Table.Customer.EmailNotification" }) }
											</label>
										</div>
									</div>
								</div>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<button
								type="button"
								onClick={onHide}
								className="btn btn-light btn-elevate"
							>
								{ intl.formatMessage({ id: "Table.Cancel" }) }
							</button>
							<> </>
							<button
								type="submit"
								onClick={() => handleSubmit()}
								className="btn btn-primary btn-elevate"
							>
								{ intl.formatMessage({ id: "Table.Save" }) }
							</button>
						</Modal.Footer>
					</>
				)}
			</Formik>
		</>
	);
}
