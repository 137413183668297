// import React, { Suspense, lazy } from "react";
// import { Redirect, Switch, Route } from "react-router-dom";
import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { Page as HomePage } from "./pages/home/page";
import { Page as NewsPage } from "./pages/news/page";
import { Page as PromotionPage } from "./pages/promotion/page";
import { Page as RedeemPage } from "./pages/redeem/page";
import { Page as PointsRecordPage } from "./pages/points-record/page";
import { Page as CustomerPage } from "./pages/customer/page";
import { Page as StorePage } from "./pages/store/page";

export default function BasePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/home" />
				}
				<ContentRoute path="/home" component={HomePage} />
				<ContentRoute path="/news" component={NewsPage} />
				<ContentRoute path="/promotion" component={PromotionPage} />
				<ContentRoute path="/redeem" component={RedeemPage} />
				<ContentRoute path="/points" component={PointsRecordPage} />
				<ContentRoute path="/customer" component={CustomerPage} />
				<ContentRoute path="/store" component={StorePage} />
				
				<Redirect to="error/error-v1" />
			</Switch>
		</Suspense>
	);
}
