import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../_metronic/_partials/controls";
import * as actions from "./_redux/actions";
import { useUIContext} from "./uiContext";
import { PATH } from "./page";
import { useIntl } from "react-intl";

export function DeleteDialog({ id, show, onHide }) {
	// Customers UI Context
	const intl = useIntl();
	const UIContext = useUIContext();
	const UIProps = useMemo(() => {
		return {
			setIds: UIContext.setIds,
			queryParams: UIContext.queryParams
		};
	}, [UIContext]);

	// Customers Redux state
	const dispatch = useDispatch();
	const { isLoading } = useSelector(
		(state) => ({ isLoading: state[PATH].actionsLoading }),
		shallowEqual
	);

	// if !id we should close modal
	useEffect(() => {
		if (!id) {
			onHide();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	// looking for loading/dispatch
	useEffect(() => {}, [isLoading, dispatch]);

	const deleteItem = () => {
		// server request for deleting customer by id
		dispatch(actions.deleteItem(id)).then(() => {
			// refresh list after deletion
			dispatch(actions.fetchItems(UIProps.queryParams));
			// clear selections list
			UIProps.setIds([]);
			// closing delete modal
			onHide();
		});
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="example-modal-sizes-title-lg"
			centered
		>
			{/*begin::Loading*/}
			{isLoading && <ModalProgressBar />}
			{/*end::Loading*/}
			<Modal.Header closeButton>
				<Modal.Title id="example-modal-sizes-title-lg">
					{ intl.formatMessage({ id: "Table.Promotion.DeleteNews" }) } 
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{!isLoading && (
					<span>{ intl.formatMessage({ id: "Table.ConfirmDelete" }) }</span>
				)}
				{isLoading && <span></span>}
			</Modal.Body>
			<Modal.Footer>
				<div>
					<button
						type="button"
						onClick={onHide}
						className="btn btn-light btn-elevate"
					>
						{ intl.formatMessage({ id: "Table.Cancel" }) } 
					</button>
					<> </>
					<button
						type="button"
						onClick={deleteItem}
						className="btn btn-primary btn-elevate"
					>
						{ intl.formatMessage({ id: "Table.Delete" }) } 
					</button>
				</div>
			</Modal.Footer>
		</Modal>
	);
}
