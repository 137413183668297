// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
	PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "./_redux/actions";
import {
	getSelectRow,
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
	sortCaret,
	headerSortingClasses,
} from "../../../_metronic/_helpers";
import * as uiHelpers from "./uiHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../_metronic/_partials/controls";
import { useUIContext } from "./uiContext";
import { PATH } from "./page";

import { useIntl } from "react-intl";

export function Table() {
	const intl = useIntl();
	const UIContext = useUIContext();
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.ids,
			setIds: UIContext.setIds,
			queryParams: UIContext.queryParams,
			setQueryParams: UIContext.setQueryParams,
			editItem: UIContext.editItem,
			deleteItem: UIContext.deleteItem,
		};
	}, [UIContext]);

	// Getting curret state of customers list from store (Redux)
	const { currentState } = useSelector(
		(state) => ({ currentState: state.store }),
		shallowEqual
	);
	const { totalCount, entities, listLoading } = currentState;

	// Customers Redux state
	const dispatch = useDispatch();
	useEffect(() => {
		// clear selections list
		UIProps.setIds([]);
		// server call by queryParams
		dispatch(actions.fetchItems(UIProps.queryParams));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [UIProps.queryParams, dispatch]);
	// Table columns
	const columns = [
		{
			dataField: "name",
			text: intl.formatMessage({ id: "Table.Store.Name" }),
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: "district",
			text: intl.formatMessage({ id: "Table.Store.District" }),
			sort: true,
			sortCaret: sortCaret,
			formatter: function (cell, row) {
				return (
					<>
						<span>{ row.district }</span>
						<br />
						<span>{ row.district_eng }</span>
					</>
				)
			},
			headerSortingClasses,
		},
		{
			dataField: "address",
			text: intl.formatMessage({ id: "Table.Store.Address" }),
			sort: true,
			sortCaret: sortCaret,
			formatter: function (cell, row) {
				return (
					<>
						<span>{ row.address }</span>
						<br />
						<span>{ row.address_eng }</span>
					</>
				)
			},
			headerSortingClasses,
		},
		{
			dataField: "contact",
			text: intl.formatMessage({ id: "Table.Store.Phone" }),
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: "whatsapp",
			text: intl.formatMessage({ id: "Table.Store.Whatsapp" }),
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: "status",
			text: intl.formatMessage({ id: "Table.Store.Status" }),
			sort: true,
			sortCaret: sortCaret,
			formatter: columnFormatters.StatusColumnFormatter,
			formatExtraData: {
				intl: intl
			},
			headerSortingClasses,
		},
		{
			dataField: "action",
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				editItem: UIProps.editItem,
				deleteItem: UIProps.deleteItem,
			},
			classes: "text-right pr-0",
			headerClasses: "text-right pr-3",
			style: {
				minWidth: "100px",
			},
		},
	];
	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: UIProps.queryParams.pageSize,
		page: UIProps.queryParams.pageNumber,
	};
	return (
		<>
			<PaginationProvider pagination={paginationFactory(paginationOptions)}>
				{({ paginationProps, paginationTableProps }) => {
					return (
						<Pagination
							isLoading={listLoading}
							paginationProps={paginationProps}
						>
							<BootstrapTable
								wrapperClasses="table-responsive"
								bordered={false}
								classes="table table-head-custom table-vertical-center overflow-hidden"
								bootstrap4
								remote
								keyField="id"
								data={entities === null ? [] : entities}
								columns={columns}
								defaultSorted={uiHelpers.defaultSorted}
								onTableChange={getHandlerTableChange(
									UIProps.setQueryParams
								)}
								selectRow={getSelectRow({
									entities,
									ids: UIProps.ids,
									setIds: UIProps.setIds,
								})}
								{...paginationTableProps}
							>
								<PleaseWaitMessage entities={entities} />
								<NoRecordsFoundMessage entities={entities} />
							</BootstrapTable>
						</Pagination>
					);
				}}
			</PaginationProvider>
		</>
	);
}
