import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./uiHelpers";

const UIContext = createContext();

export function useUIContext() {
	return useContext(UIContext);
}

export const UIConsumer = UIContext.Consumer;

export function UIProvider({events, children}) {
	const [queryParams, setQueryParamsBase] = useState(initialFilter);
	const [ids, setIds] = useState([]);
	const setQueryParams = useCallback(nextQueryParams => {
		setQueryParamsBase(prevQueryParams => {
			if (isFunction(nextQueryParams)) {
				nextQueryParams = nextQueryParams(prevQueryParams);
			}

			if (isEqual(prevQueryParams, nextQueryParams)) {
				return prevQueryParams;
			}

			return nextQueryParams;
		});
	}, []);

	const initItem = {
		nickname: "",
		email: "",
		mobile: "",
		points: 0,
		status: 0
	};

	const value = {
		queryParams,
		setQueryParamsBase,
		ids,
		setIds,
		setQueryParams,
		initItem,
		createItem: events.createItem,
		editItem: events.editItem,
		deleteItem: events.deleteItem,
		deleteItems: events.deleteItems
	};

	return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}