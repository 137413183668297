// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
	Input,
	Select,
	DatePickerField,
} from "../../../_metronic/_partials/controls";

import { Button, Image } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';

import { useIntl } from "react-intl";

// Validation schema
const EditSchema = Yup.object().shape({
	title: Yup.string()
		.min(3, "Minimum 3 symbols")
		.max(50, "Maximum 50 symbols")
		.required("Firstname is required"),
	desc: Yup.string()
		.min(3, "Minimum 3 symbols")
		.max(50, "Maximum 50 symbols")
		.required("Lastname is required")
});

export function EditForm({ saveItem, item, actionsLoading, onHide }) {

	const intl = useIntl();
    const [file, setFile] = React.useState(null)
    const fileHandler = (e) => {
        setFile(e.target.files[0])
    }

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={item}
				onSubmit={(values) => {
					saveItem(values);
				}}
			>
				{({ handleChange, handleSubmit }) => (
					<>
						<Modal.Body className="overlay overlay-block cursor-default">
							{actionsLoading && (
								<div className="overlay-layer bg-transparent">
									<div className="spinner spinner-lg spinner-success" />
								</div>
							)}
							<Form className="form form-label-right">
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Title" }) }
									</label>
									<div className="col">
										{ intl.formatMessage({ id: "Common.Chinese" }) }
										<Field
											name="title"
											initialvalue={ item.title || '' }
											component={Input}
											placeholder={ intl.formatMessage({ id: "Table.Title" }) }
										/>
									</div>
									<div className="col">
										{ intl.formatMessage({ id: "Common.English" }) }
										<Field
											name="title_eng"
											initialvalue={ item.title_eng || '' }
											component={Input}
											placeholder={ intl.formatMessage({ id: "Table.Title" }) + "-" + intl.formatMessage({ id: "Common.English" }) }
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Content" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Field
											as={Editor}
											apiKey="jy9xmo0jvtxdivpgf49m1znn36njczcg4bz16ur5ogygoswa"
											name="desc"
											initialvalue={ item.desc }
											onEditorChange={(e) => {
												handleChange({ target: { name: 'desc', value: e } })
											}}
											init={{
												skin: "material-classic",
												height: 200,
												menubar: false,
												plugins: [
													'link wordcount emoticons'
												],
												toolbar:
												'undo redo | emoticons link formatselect  | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
											}}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Content" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Field
											as={Editor}
											apiKey="jy9xmo0jvtxdivpgf49m1znn36njczcg4bz16ur5ogygoswa"
											name="desc_eng"
											initialvalue={ item.desc_eng }
											onEditorChange={(e) => {
												handleChange({ target: { name: 'desc_eng', value: e } })
											}}
											init={{
												skin: "material-classic",
												height: 200,
												menubar: false,
												plugins: [
													'link wordcount emoticons'
												],
												toolbar:
												'undo redo | emoticons link formatselect  | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
											}}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Image" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<input id="image" name="image" type="file" onChange={(e) => {
												handleChange({ target: { name: 'image', value: e.currentTarget.files[0] } })
												fileHandler(e)
											}} hidden />
										<Button
											type="button"
											className="btn btn-light-primary  btn-elevate"
											variant="contained"
											component="span"
											onClick={() => document.getElementById("image").click() }
										>
											{ intl.formatMessage({ id: "Table.UploadImage" }) }
										</Button>
										<br />
										<Image className="w-25" src={file? URL.createObjectURL(file) : null} alt={file? file.name : null} rounded />
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Type" }) }
									</label>
									<div className="col-lg-9 col-xl-6 p-3">
										<Select name="type">
											{/*<option value="0">{ intl.formatMessage({ id: "Table.Normal" }) }</option>*/}
											<option value="1">{ intl.formatMessage({ id: "Table.Product" }) }</option>
											<option value="2">{ intl.formatMessage({ id: "Table.NotProduct" }) }</option>
										</Select>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Points" }) }
									</label>
									<div className="col">
										<Field
											name="points"
											initialvalue={ item.points || '' }
											component={Input}
											placeholder={ intl.formatMessage({ id: "Table.Points" }) }
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Price" }) }
									</label>
									<div className="col">
										<Field
											name="price"
											initialvalue={ item.price || '' }
											component={Input}
											placeholder={ intl.formatMessage({ id: "Table.Price" }) }
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.StartDate" }) }
									</label>
									<div className="col-lg-3 col-xl-3">
										<DatePickerField name="start_date" />
									</div>
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.EndDate" }) }
									</label>
									<div className="col-lg-3 col-xl-3">
										<DatePickerField name="end_date" />
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Status" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Select name="status">
											<option value="0">{ intl.formatMessage({ id: "Table.Suspended" }) }</option>
											<option value="1">{ intl.formatMessage({ id: "Table.Active" }) }</option>
										</Select>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.PushNotification" }) }
									</label>
									<div className="col-lg-9 col-xl-6 p-3">
										<div className="checkbox-inline">
											<label className="checkbox">
												<Field type="checkbox" name="push_noti" />
												<span></span>
											</label>
										</div>
									</div>
								</div>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<button
								type="button"
								onClick={onHide}
								className="btn btn-light btn-elevate"
							>
								{ intl.formatMessage({ id: "Table.Cancel" }) }
							</button>
							<> </>
							<button
								type="submit"
								onClick={() => handleSubmit()}
								className="btn btn-primary btn-elevate"
							>
								{ intl.formatMessage({ id: "Table.Save" }) }
							</button>
						</Modal.Footer>
					</>
				)}
			</Formik>
		</>
	);
}
