// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

const ItemStatusCssClasses = ["success", "info", "error"];
const ItemStatusTitles = ["Table.Paid", "Table.InTransit", "Table.Completed"];

export function StatusColumnFormatter(cellContent, row, rowIndex, { intl }) {

	const getLabelCssClasses = () => {
		if (!ItemStatusCssClasses[row.status]) {
			return 'label label-lg label-light-danger label-inline';
		} else {
			return `label label-lg label-inline label-light-${ ItemStatusCssClasses[row.status] }`;
		}
	};

	const getLabel = () => {
		if (!ItemStatusTitles[row.status]) {
			return intl.formatMessage({ id: "Table.Cancelled" });
		} else {
			return intl.formatMessage({ id: ItemStatusTitles[row.status] });
		}
	}

	return (
		<span className={ getLabelCssClasses() }>
			{ getLabel() }
		</span>
	);
}
