import React, { useMemo } from "react";
import { useUIContext } from "./uiContext";
import { useIntl } from "react-intl";

export function Grouping() {
	// Customers UI Context
	const intl = useIntl();
	const UIContext = useUIContext();
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.ids,
			setIds: UIContext.setIds,
			deleteItems: UIContext.deleteItems
		};
	}, [UIContext]);

	return (
		<div className="form">
			<div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
				<div className="col-xl-12">
					<div className="form-group form-group-inline">
						<div className="form-label form-label-no-wrap">
							<label className="font-bold font-danger">
								<span>
									{ intl.formatMessage({ id: "Table.SelectedRow" }) }: <b>{UIProps.ids.length}</b>
								</span>
							</label>
						</div>
						<div>
							<button
								type="button"
								className="btn btn-danger font-weight-bolder font-size-sm"
								onClick={UIProps.deleteItems}
							>
								<i className="fa fa-trash"></i> { intl.formatMessage({ id: "Table.DeleteAll" }) }
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
