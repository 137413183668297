// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {Dropdown} from "react-bootstrap";
import {DropdownCustomToggler} from "../../../../_metronic/_partials/dropdowns";

export function ActionsColumnFormatter(
	cellContent,
	row,
	rowIndex,
	{ intl, editItem }
) {
	return (
		<>
			<Dropdown className="dropdown-inline" alignRight>
				<Dropdown.Toggle
					id="dropdown-toggle-top"
                	as={DropdownCustomToggler}>
  					<i className="ki ki-bold-more-hor" />
				</Dropdown.Toggle>
				<Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
					<Dropdown.Item onClick={() => editItem(row._id, -1)}>{ intl.formatMessage({ id: "Table.Cancelled" }) }</Dropdown.Item>
					<Dropdown.Item onClick={() => editItem(row._id, 0)}>{ intl.formatMessage({ id: "Table.Paid" }) }</Dropdown.Item>
					<Dropdown.Item onClick={() => editItem(row._id, 1)}>{ intl.formatMessage({ id: "Table.InTransit" }) }</Dropdown.Item>
					<Dropdown.Item onClick={() => editItem(row._id, 2)}>{ intl.formatMessage({ id: "Table.Completed" }) }</Dropdown.Item>
				</Dropdown.Menu>
				</Dropdown>	
		</>
	);
}