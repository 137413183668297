import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useUIContext } from "./uiContext";
import { useIntl } from "react-intl";

const prepareFilter = (queryParams, values) => {
	const { status, searchText } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};
	// Filter by status
	filter.status = status !== "" ? + status : undefined;
	// Filter by all fields
	if (searchText) {
		filter.title = searchText;
		filter.title_eng = searchText;
		filter.desc = searchText;
		filter.desc_eng = searchText;
	}
	newQueryParams.filter = filter;
	return newQueryParams;
};

export function Filter({ listLoading }) {
	// Customers UI Context
	const intl = useIntl();
	const UIContext = useUIContext();
	const UIProps = useMemo(() => {
		return {
			queryParams: UIContext.queryParams,
			setQueryParams: UIContext.setQueryParams,
		};
	}, [UIContext]);

	// queryParams, setQueryParams,
	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(UIProps.queryParams, values);
		if (!isEqual(newQueryParams, UIProps.queryParams)) {
			newQueryParams.pageNumber = 1;
			// update list by queryParams
			UIProps.setQueryParams(newQueryParams);
		}
	};

	return (
		<>
			<Formik
				initialValues={{
					status: "", // values => All=""/Susspended=0/Active=1/Pending=2
					searchText: "",
				}}
				onSubmit={(values) => {
					applyFilter(values);
				}}
			>
				{({
					values,
					handleSubmit,
					handleBlur,
					handleChange,
					setFieldValue,
				}) => (
					<form onSubmit={handleSubmit} className="form form-label-right">
						<div className="form-group row">
							<div className="col-lg-2">
								<select
									className="form-control form-control-solid"
									name="status"
									placeholder={intl.formatMessage({ id: "Table.News.FilterByStatus" })}
									// TODO: Change this code
									onChange={(e) => {
										setFieldValue("status", e.target.value);
										handleSubmit();
									}}
									onBlur={handleBlur}
									value={values.status}
								>
									<option value="">{intl.formatMessage({ id: "Table.News.All" })}</option>
									<option value="0">{intl.formatMessage({ id: "Table.News.Suspended" })}</option>
									<option value="1">{intl.formatMessage({ id: "Table.News.Active" })}</option>
								</select>
								<small className="form-text text-muted">
									{intl.formatMessage({ id: "Table.News.FilterByStatus" })}
								</small>
							</div>
							<div className="col-lg-4">
								<input
									type="text"
									className="form-control form-control-solid"
									name="searchText"
									placeholder={intl.formatMessage({ id: "Table.News.Search" })}
									onBlur={handleBlur}
									value={values.searchText}
									onChange={(e) => {
										setFieldValue("searchText", e.target.value);
										handleSubmit();
									}}
								/>
								<small className="form-text text-muted">
									{ intl.formatMessage({ id: "Table.News.Search" }) }
								</small>
							</div>
						</div>
					</form>
				)}
			</Formik>
		</>
	);
}
