// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const ItemStatusCssClasses = ["danger", "success", ""];

export function PointsColumnFormatter(cellContent, row, rowIndex) {

	const getLabelCssClasses = () => {
		return `text-${
			ItemStatusCssClasses[row.points > 0 ? "1" : "0"]
		}`;
	};

	return (
		<>
			<span className={getLabelCssClasses()}>
				<b>{ row.points > 0 ? "+" + row.points : row.points }</b>
			</span>
		</>
	);
}
