import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog as ControlLoadingDialog } from "../../../_metronic/_partials/controls";
import { PATH } from "./page";

export function LoadingDialog() {
	// Customers Redux state
	const { isLoading } = useSelector(
		(state) => ({ isLoading: state[PATH].listLoading }),
		shallowEqual
	);
	// looking for loading/dispatch
	useEffect(() => {}, [isLoading]);
	return <ControlLoadingDialog isLoading={isLoading} text="Loading ..." />;
}
