import React from "react";
import { Route } from "react-router-dom";
import { LoadingDialog } from "./loadingDialog";
import { EditDialog } from "./editDialog";
import { DeleteDialog } from "./deleteDialog";
import { MultiDeleteDialog } from "./multiDeleteDialog";
import { UIProvider } from "./uiContext";
import { Card } from "./card";

export const PATH = "promotion";

export function Page({ history }) {

	const events = {
		createItem: () => {
			history.push(`/${PATH}/create`);
		},
		editItem: (id) => {
			history.push(`/${PATH}/edit/${id}`);
		},
		deleteItem: (id) => {
			history.push(`/${PATH}/delete/${id}`);
		},
		deleteItems: () => {
			history.push(`/${PATH}/delete`);
		}
	}


	return (
		<UIProvider events={events}>
			<LoadingDialog />
			<Route path={"/" + PATH + "/create"}>
				{({ history, match }) => (
					<EditDialog
						show={match != null}
						onHide={() => {
							history.push(`/${PATH}`);
						}}
					/>
				)}
			</Route>
			<Route path={"/" + PATH + "/edit/:id"}>
				{({ history, match }) => (
					<EditDialog
						show={match != null}
						id={match && match.params.id}
						onHide={() => {
							history.push(`/${PATH}`);
						}}
					/>
				)}
			</Route>
			<Route path={"/" + PATH + "/delete"}>
				{({ history, match }) => (
					<MultiDeleteDialog
						show={match != null}
						onHide={() => {
							history.push(`/${PATH}`);
						}}
					/>
				)}
			</Route>
			<Route path={"/" + PATH + "/delete/:id"}>
				{({ history, match }) => (
					<DeleteDialog
						show={match != null}
						id={match && match.params.id}
						onHide={() => {
							history.push(`/${PATH}`);
						}}
					/>
				)}
			</Route>
			<Card />
		</UIProvider>
	);

}