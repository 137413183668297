/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

import { useIntl } from "react-intl";

export function AsideMenuList({ layoutProps }) {
	const intl = useIntl();
	const location = useLocation();
	const getMenuItemActive = (url, hasSubmenu = false) => {
		return checkIsActive(location, url)
			? ` ${!hasSubmenu &&
					"menu-item-active"} menu-item-open menu-item-not-hightlighted`
			: "";
	};

	return (
		<>
			{/* begin::Menu Nav */}
			<ul className={`menu-nav ${layoutProps.ulClasses}`}>
				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive("/home", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/home">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
						</span>
						<span className="menu-text">{intl.formatMessage({ id: "Menu.Dashboard" })}</span>
						<i className="menu-arrow" />
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive("/news", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/news">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
						</span>
						<span className="menu-text">{intl.formatMessage({ id: "Menu.News" })}</span>
						<i className="menu-arrow" />
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive("/promotion", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/promotion">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
						</span>
						<span className="menu-text">{intl.formatMessage({ id: "Menu.Promotion" })}</span>
						<i className="menu-arrow" />
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive("/redeem", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/redeem">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
						</span>
						<span className="menu-text">{intl.formatMessage({ id: "Menu.Redeem" })}</span>
						<i className="menu-arrow" />
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive("/points", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/points">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
						</span>
						<span className="menu-text">{intl.formatMessage({ id: "Menu.PointsRecord" })}</span>
						<i className="menu-arrow" />
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive("/customer", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/customer">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
						</span>
						<span className="menu-text">{intl.formatMessage({ id: "Menu.Customer" })}</span>
						<i className="menu-arrow" />
					</NavLink>
				</li>
				{/*end::1 Level*/}

				{/*begin::1 Level*/}
				<li
					className={`menu-item ${getMenuItemActive("/store", false)}`}
					aria-haspopup="true"
				>
					<NavLink className="menu-link" to="/store">
						<span className="svg-icon menu-icon">
							<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
						</span>
						<span className="menu-text">{intl.formatMessage({ id: "Menu.Store" })}</span>
						<i className="menu-arrow" />
					</NavLink>
				</li>
				{/*end::1 Level*/}


			</ul>

			{/* end::Menu Nav */}
		</>
	);
}
