import {createSlice} from "@reduxjs/toolkit";

const initialState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: null,
	forEdit: undefined,
	lastError: null
};
export const callTypes = {
	list: "list",
	action: "action"
};

export const slice = createSlice({
	name: "item",
	initialState: initialState,
	reducers: {
		catchError: (state, action) => {
			state.error = `${action.type}: ${action.payload.error}`;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false;
			} else {
				state.actionsLoading = false;
			}
		},
		startCall: (state, action) => {
			state.error = null;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true;
			} else {
				state.actionsLoading = true;
			}
		},
		itemFetched: (state, action) => {
			state.actionsLoading = false;
			state.forEdit = action.payload.forEdit;
			state.error = null;
		},
		itemsFetched: (state, action) => {
			const { totalCount, entities } = action.payload;
			state.listLoading = false;
			state.error = null;
			state.entities = entities;
			state.totalCount = totalCount;
		},
		itemCreated: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
			state.entities.push(action.payload.item);
		},
		itemUpdated: (state, action) => {
			state.error = null;
			state.actionsLoading = false;
			state.entities = state.entities.map(entity => {
				if (entity._id === action.payload.item._id) {
					return action.payload.item;
				}
				return entity;
			});
		},
		itemDeleted: (state, action) => {
			state.error = null;
			state.actionsLoading = false;
			state.entities = state.entities.filter(el => el._id !== action.payload.id);
		},
		itemsDeleted: (state, action) => {
			state.error = null;
			state.actionsLoading = false;
			state.entities = state.entities.filter(
				el => !action.payload.ids.includes(el._id)
			);
		},
		sendNoti: (state, action) => {
			state.error = null;
			state.actionsLoading = false;
		}
	}
});
