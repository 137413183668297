/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
// import React, { useMemo } from "react";
import React from "react";
import { Link } from "react-router-dom";
// import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
// import objectPath from "object-path";
// import { useHtmlClassService } from "../../../_core/MetronicLayout";
// import { toAbsoluteUrl } from "../../../../_helpers";
// import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useIntl } from "react-intl";

export function UserProfileDropdown() {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  // const uiService = useHtmlClassService();
  // const layoutProps = useMemo(() => {
  //   return {
  //     light:
  //       objectPath.get(uiService.config, "extras.user.dropdown.style") ===
  //       "light",
  //   };
  // }, [uiService]);

  return (
    <>
      <div className="topbar-item">
        <div
          className={
            "w-auto d-flex align-items-center px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            {intl.formatMessage({ id: "Common.Hi" })},
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {user.firstname} {user.lastname}
          </span>
          <span className="symbol symbol-35 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {user.firstname[0]}
            </span>
          </span>
        </div>
      </div>
      <div className="topbar-item">
        <Link
          to="/logout"
          className="btn btn-sm btn-light-primary font-weight-bold"
        >
          {intl.formatMessage({ id: "Auth.Logout" })}
        </Link>
      </div>
    </>
  );
}
