import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "./_redux/actions";
import { EditDialogHeader } from "./editDialogHeader";
import { EditForm } from "./editForm";
import { useUIContext } from "./uiContext";
import { PATH } from "./page";

export function EditDialog({ id, show, onHide }) {
	const UIContext = useUIContext();
	const UIProps = useMemo(() => {
		return {
			initItem: UIContext.initItem,
		};
	}, [UIContext]);

	const dispatch = useDispatch();
	const { actionsLoading, forEdit } = useSelector(
		(state) => ({
			actionsLoading: state[PATH].actionsLoading,
			forEdit: state[PATH].forEdit,
		}),
		shallowEqual
	);

	useEffect(() => {
		// server call for getting Customer by id
		dispatch(actions.fetchItem(id));
	}, [id, dispatch]);

	// server request for saving customer
	const saveItem = (item) => {
		if (!id) {
			// server request for creating customer
			dispatch(actions.createItem(item)).then(() => onHide());
		} else {
			// server request for updating customer
			dispatch(actions.updateItem(item)).then(() => onHide());
		}
	};

	return (
		<Modal
			size="lg"
			show={show}
			onHide={onHide}
			aria-labelledby="example-modal-sizes-title-lg"
			centered
		>
			<EditDialogHeader id={id} />
			<EditForm
				saveItem={saveItem}
				actionsLoading={actionsLoading}
				item={forEdit || UIProps.initItem}
				onHide={onHide}
			/>
		</Modal>
	);
}
