import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../_metronic/_partials/controls";

import { PATH } from "./page";
import { useIntl } from "react-intl";

export function EditDialogHeader({ id }) {
	const intl = useIntl();
	// Customers Redux state
	const { forEdit, actionsLoading } = useSelector(
		(state) => ({
			forEdit: state[PATH].forEdit,
			actionsLoading: state[PATH].actionsLoading,
		}),
		shallowEqual
	);

	const [title, setTitle] = useState("");
	// Title couting
	useEffect(() => {
		let _title = id ? "" : intl.formatMessage({ id: "Table.Store.CreateStore" });
		if (forEdit && id) {
			_title = `${intl.formatMessage({ id: "Table.Store.EditStore" })} - ${forEdit.name}`;
		}

		setTitle(_title);
		// eslint-disable-next-line
	}, [forEdit, actionsLoading]);

	return (
		<>
			{actionsLoading && <ModalProgressBar />}
			<Modal.Header closeButton>
				<Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
			</Modal.Header>
		</>
	);
}
