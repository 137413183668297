export const ItemStatusCssClasses = ["danger", "success", ""];
export const ItemStatusTitles = ["Suspended", "Active", ""];
export const defaultSorted = [{ dataField: "_id", order: "-1" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 }
];
export const initialFilter = {
  filter: {
    customer: "",
    promotion: "",
    staff: ""
  },
  sortOrder: -1,
  sortField: "_id",
  pageNumber: 1,
  pageSize: 10
};
