import * as requestFromServer from "./crud";
import {slice, callTypes} from "./slice";

const {actions} = slice;

export const fetchItems = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getItems(queryParams)
		.then(response => {
			const { totalCount, entities } = response.data;
			dispatch(actions.itemsFetched({ totalCount, entities }));
		})
		.catch(error => {
			error.clientMessage = "Can't find";
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchItem = id => dispatch => {
	if (!id) {
		return dispatch(actions.itemFetched({ forEdit: undefined }));
	}

	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.getItemById(id)
		.then(response => {
			const data = response.data;
			dispatch(actions.itemFetched({ forEdit: data }));
		})
		.catch(error => {
			error.clientMessage = "Can't find";
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const deleteItem = id => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.deleteItem(id)
		.then(response => {
			dispatch(actions.itemDeleted({ id }));
		})
		.catch(error => {
			error.clientMessage = "Can't delete";
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const createItem = forCreation => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.createItem(forCreation)
		.then(response => {
			const { data } = response.data;
			dispatch(actions.itemCreated({ data }));
		})
		.catch(error => {
			error.clientMessage = "Can't create";
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const updateItem = item => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.updateItem(item)
		.then(() => {
			dispatch(actions.itemUpdated({ item }));
		})
		.catch(error => {
			error.clientMessage = "Can't update";
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const deleteItems = ids => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.deleteItems(ids)
		.then(() => {
			dispatch(actions.itemsDeleted({ ids }));
		})
		.catch(error => {
			error.clientMessage = "Can't delete";
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};
