import React, { useMemo } from "react";
import {
	Card as ControlCard,
	CardBody,
	CardHeader,
	// CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Filter } from "./filter";
import { Table } from "./table";
import { Grouping } from "./grouping";
import { useUIContext } from "./uiContext";
import { useIntl } from "react-intl";

export function Card() {
	const intl = useIntl();
	const UIContext = useUIContext();
	const UIProps = useMemo(() => {
		return {
			ids: UIContext.ids,
			createItem: UIContext.createItem,
		};
	}, [UIContext]);

	return (
		<ControlCard>
			<CardHeader title={ intl.formatMessage({ id: "Table.Points.List" }) } />
			<CardBody>
				<Filter />
				{UIProps.ids.length > 0 && <Grouping />}
				<Table />
			</CardBody>
		</ControlCard>
	);
}
