import axios from "axios";

import { PATH } from "../page";

export const URL = `/api/cms`;

export function createItem(item) {
  const formData = new FormData();
  for (var key in item) {
    formData.append(key, item[key]);
  }
  return axios.post(`${URL}/${PATH}/create`, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
  });
}


export function getAllItems() {
  return axios.get(`${URL}/${PATH}`);
}

export function getItemById(id) {
  return axios.get(`${URL}/${PATH}/${id}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function getItems(queryParams) {
  return axios.post(`${URL}/${PATH}`, { queryParams });
}

export function updateItem(item) {
	const formData = new FormData();
	for (var key in item) {
		formData.append(key, item[key]);
	}
  	return axios.post(`${URL}/${PATH}/${item._id}`, formData, {
  		headers: {
      		'Content-Type': 'multipart/form-data'
    	}
	});
}

export function deleteItem(id) {
  return axios.delete(`${URL}/${PATH}/${id}`);
}

export function deleteItems(ids) {
  return axios.post(`${URL}/${PATH}/delete`, { ids });
}
