// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
	Input,
	Select,
	DatePickerField,
} from "../../../_metronic/_partials/controls";

import { Button, Image } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';

import { useIntl } from "react-intl";

// Validation schema
const EditSchema = Yup.object().shape({
	title: Yup.string()
		.min(3, "Minimum 3 symbols")
		.max(50, "Maximum 50 symbols")
		.required("Firstname is required"),
	desc: Yup.string()
		.min(3, "Minimum 3 symbols")
		.max(50, "Maximum 50 symbols")
		.required("Lastname is required")
});

export function EditForm({ saveItem, item, actionsLoading, onHide }) {

	const intl = useIntl();
    const [file, setFile] = React.useState(null)
    const fileHandler = (e) => {
        setFile(e.target.files[0])
    }

	return (
		<>
			<Formik
				enableReinitialize={true}
				initialValues={item}
				validationSchema={EditSchema}
				onSubmit={(values) => {
					saveItem(values);
				}}
			>
				{({ handleSubmit }) => (
					<>
						<Modal.Body className="overlay overlay-block cursor-default">
							{actionsLoading && (
								<div className="overlay-layer bg-transparent">
									<div className="spinner spinner-lg spinner-success" />
								</div>
							)}
							<Form className="form form-label-right">
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Promotion.Title" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Field
											name="title"
											component={Input}
											placeholder={ intl.formatMessage({ id: "Table.Promotion.Title" }) }
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Promotion.Content" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Editor
											apiKey="jy9xmo0jvtxdivpgf49m1znn36njczcg4bz16ur5ogygoswa"
											name="content"
											initialValue=""
											init={{
												skin: "material-classic",
												icons: "jam",
												height: 200,
												menubar: false,
												plugins: [
													'advlist autolink lists link image charmap print preview anchor',
													'searchreplace visualblocks code fullscreen',
													'insertdatetime media table paste code help wordcount'
												],
												toolbar:
												'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
											}}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Promotion.Image" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<input id="image" name="image" type="file" onChange={fileHandler} hidden />
										<Button
											type="button"
											className="btn btn-light-primary  btn-elevate"
											variant="contained"
											component="span"
											onClick={() => document.getElementById("image").click() }
										>
											{ intl.formatMessage({ id: "Table.Promotion.UploadImage" }) }
										</Button>
										<br />
										<Image className="w-25" src={file? URL.createObjectURL(file) : null} alt={file? file.name : null} rounded />
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Promotion.StartDate" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<DatePickerField name="startDate" />
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Promotion.EndDate" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<DatePickerField name="endDate" />
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.Promotion.Status" }) }
									</label>
									<div className="col-lg-9 col-xl-9">
										<Select name="status">
											<option value="0">{ intl.formatMessage({ id: "Table.Promotion.Suspended" }) }</option>
											<option value="1">{ intl.formatMessage({ id: "Table.Promotion.Active" }) }</option>
										</Select>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-xl-3 col-lg-3 col-form-label">
										{ intl.formatMessage({ id: "Table.PushNotification" }) }
									</label>
									<div className="col-lg-9 col-xl-6 p-3">
										<div className="checkbox-inline">
											<label className="checkbox">
												<input type="checkbox" name="pushNotification" />
												<span></span>
											</label>
										</div>
									</div>
								</div>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<button
								type="button"
								onClick={onHide}
								className="btn btn-light btn-elevate"
							>
								{ intl.formatMessage({ id: "Table.Cancel" }) }
							</button>
							<> </>
							<button
								type="submit"
								onClick={() => handleSubmit()}
								className="btn btn-primary btn-elevate"
							>
								{ intl.formatMessage({ id: "Table.Save" }) }
							</button>
						</Modal.Footer>
					</>
				)}
			</Formik>
		</>
	);
}
