import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { slice as orderSlice } from "../app/pages/home/_redux/slice";
import { slice as newsSlice } from "../app/pages/news/_redux/slice";
import { slice as promotionSlice } from "../app/pages/promotion/_redux/slice";
import { slice as redeemSlice } from "../app/pages/redeem/_redux/slice";
import { slice as pointsRecordSlice } from "../app/pages/points-record/_redux/slice";
import { slice as customerSlice } from "../app/pages/customer/_redux/slice";
import { slice as storeSlice } from "../app/pages/store/_redux/slice";

export const rootReducer = combineReducers({
	auth: auth.reducer,
	news: newsSlice.reducer,
	promotion: promotionSlice.reducer,
	redeem: redeemSlice.reducer,
	points: pointsRecordSlice.reducer,
	customer: customerSlice.reducer,
	store: storeSlice.reducer,
	order: orderSlice.reducer
});

export function* rootSaga() {
	yield all([auth.saga()]);
}
