// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

const ItemStatusCssClasses = ["error", "info", ""];
const ItemStatusTitles = ["Table.Customer", "Table.Staff"];

export function TypeColumnFormatter(cellContent, row, rowIndex, { intl }) {

	const getLabelCssClasses = () => {
		return `label label-lg label-light-${
			ItemStatusCssClasses[row.type]
		} label-inline`;
	};

	if (typeof(row.type) !== "undefined" && row.type < 2) {
		return (
			<span className={getLabelCssClasses()}>
				{ intl.formatMessage({ id: ItemStatusTitles[row.type] }) }
			</span>
		);
	} else {
		return (<span></span>)
	}
}
