import axios from "axios";

import { PATH } from "../page";

export const URL = `/api/cms`;

export function createItem(item) {
  return axios.post(`${URL}/${PATH}/create`, { item });
}


export function getAllItems() {
  return axios.get(`${URL}/${PATH}`);
}

export function getItemById(id) {
  return axios.get(`${URL}/${PATH}/${id}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function getItems(queryParams) {
  return axios.post(`${URL}/${PATH}`, { queryParams });
}

export function updateItem(item) {
  return axios.put(`${URL}/${PATH}/${item.id}`, { item });
}

export function deleteItem(id) {
  return axios.delete(`${URL}/${PATH}/${id}`);
}

export function deleteItems(ids) {
  return axios.post(`${URL}/${PATH}/delete`, { ids });
}
